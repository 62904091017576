const months: { [key: string]: string } = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
}
// takes a date string from polaris like "2020-05-23" and converts it
// to a format that arils uses like "23-May-20"
const dateFormatter = (polarisDateString: string): string => {
  if (!polarisDateString) return ''

  const [year, month, day] = polarisDateString.split('-')
  if (!year || !month || !day || year.length !== 4)
    throw new Error('Polaris date is not in the expected format')
  return `${day}-${months[month]}-${year.substr(2, 2)}`
}

export default dateFormatter
