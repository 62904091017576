import React, { useState } from 'react'
import {
  Form,
  FormField,
  Input,
  ColumnLayout,
  Button,
  Table,
  TablePagination,
  TableSorting,
  DatePicker,
} from '@amzn/awsui-components-react/polaris'
import DataField from './DataField'
import InvoiceLines from './InvoiceLines'
import Status from './Status'
import { useZoomAWS } from '../api'
import { PostZoomAWS } from '@amzn/zoom-dashboard-backend/src/types'
import DateRangePicker from './DateRangePicker'
import dateFormatter from './dateFormatter'
import InvoiceErrors from './InvoiceErrors'
import Transmission from './Transmission'

const AWS = () => {
  const [transactionNumber, setTransactionNumber] = useState('')
  const [accountId, setAccountId] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [query, setQuery] = useState<PostZoomAWS.Request>({})
  const [error, setError] = useState<string | null>(null)
  const { data, loading } = useZoomAWS(query)
  const [invoice, setInvoice] = useState<PostZoomAWS.Invoice | null>(null)

  if (invoice)
    return <DisplayInvoice invoice={invoice} onClose={() => setInvoice(null)} />

  const onSubmit = (e: any) => {
    e.preventDefault()
    if ((fromDate || toDate) && !accountId) {
      setError(
        'If you set the date filters, you must also specify an account ID.'
      )
    } else if (accountId && !(fromDate || toDate)) {
      setError(
        'If you specify an account ID, you must also set the date filters.'
      )
    } else {
      setQuery({
        transactionNumber,
        accountId,
        fromDate: dateFormatter(fromDate),
        toDate: dateFormatter(toDate),
      })
      setError(null)
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Form
          actions={
            <div className="awsui-util-pt-m">
              <Button variant="primary" onClick={onSubmit}>
                Search
              </Button>
              <Button
                onClick={() => {
                  setTransactionNumber('')
                  setAccountId('')
                  setFromDate('')
                  setToDate('')
                }}
              >
                Clear
              </Button>
            </div>
          }
          errorText={error}
        >
          <ColumnLayout reset-filter-link columns={3}>
            <div
              data-awsui-column-layout-root="true"
              style={{ paddingBottom: 5 }}
            >
              <FormField label="Transaction Number">
                <Input
                  controlId="awstrxnum"
                  placeholder="e.g. 1625663893"
                  value={transactionNumber}
                  onChange={(e) => setTransactionNumber(e.detail.value)}
                />
              </FormField>
            </div>
          </ColumnLayout>
          <ColumnLayout reset-filter-link columns={3}>
            <div
              data-awsui-column-layout-root="true"
              style={{ paddingBottom: 5 }}
            >
              <FormField label="Account ID">
                <Input
                  controlId="awsaccountid"
                  placeholder="e.g. 105293286752"
                  value={accountId}
                  onChange={(e) => setAccountId(e.detail.value)}
                />
              </FormField>
              <FormField label="Date Range">
                <DateRangePicker
                  from={
                    <DatePicker
                      controlId="awsfrom"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.detail.value)}
                    />
                  }
                  to={
                    <DatePicker
                      controlId="awsto"
                      value={toDate}
                      onChange={(e) => setToDate(e.detail.value)}
                    />
                  }
                />
              </FormField>
            </div>
          </ColumnLayout>
        </Form>
      </form>
      <Table
        loading={loading}
        loadingText="Searching"
        columnDefinitions={columnDefinitions}
        items={data?.invoices || []}
        features={['pagination', 'sorting']}
        variant="borderless"
        onRowClick={(e) => setInvoice(e.detail.item)}
        className="search-results"
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No matches</b>
            </div>
          </div>
        }
      >
        <TablePagination pageSize={25} />
        <TableSorting
          sortableColumns={columnDefinitions.map((c) => ({
            id: c.id as string,
            field: c.id,
          }))}
        />
      </Table>
    </>
  )
}

const columnDefinitions: Table.ColumnDefinition[] = [
  {
    cell: (item: PostZoomAWS.Invoice) => item.trxNumber,
    header: 'Transaction Number',
    id: 'trxNumber',
  },
  {
    cell: (item: PostZoomAWS.Invoice) =>
      item.invoiceCurrencyCode || item.currencyCode, // invoices in interface tables have slightly different fields
    header: 'Currency',
    id: 'invoiceCurrencyCode',
  },
  {
    cell: (item: PostZoomAWS.Invoice) => item.location,
    header: 'Stage',
    id: 'location',
  },
  {
    cell: (item: PostZoomAWS.Invoice) => item.creationDate,
    header: 'Creation Date',
    id: 'creationDate',
  },
  {
    cell: (item: PostZoomAWS.Invoice) =>
      item.amountDueOriginal || item.totalAmount,
    header: 'Amount',
    id: 'amountDueOriginal',
  },
  {
    cell: (item: PostZoomAWS.Invoice) => item.operatingUnit,
    header: 'Org Name',
    id: 'operatingUnit',
  },
]

const DisplayInvoice = ({
  invoice,
  onClose,
}: {
  invoice: PostZoomAWS.Invoice
  onClose: () => void
}) => (
  <>
    <Button onClick={onClose}>Back to Search Results</Button>
    <div style={{ height: '2em' }} />
    <InvoiceErrors errors={invoice.errorStatus?.errorList} />
    <ColumnLayout reset-filter-link columns={4}>
      <div data-awsui-column-layout-root="true">
        <DataField label="AWS Account ID">
          {invoice.originalSystemReference}
        </DataField>
        <DataField label="Transaction Number">{invoice.trxNumber}</DataField>
        <DataField label="Currency">
          {invoice.invoiceCurrencyCode || invoice.currencyCode}
        </DataField>
        <DataField label="Name">{invoice.name}</DataField>
        <DataField label="Stage">{invoice.location}</DataField>
        <DataField label="Total Amount">
          {invoice.amountDueOriginal || invoice.totalAmount}
        </DataField>
        <DataField label="Amount Remaining">
          {invoice.amountDueOriginal}
        </DataField>
        <DataField label="Transaction Date">{invoice.trxDate}</DataField>
        <DataField label="GL Date">{invoice.glDate}</DataField>
        <DataField label="Creation Date">{invoice.creationDate}</DataField>
        <DataField label="Org Name">{invoice.operatingUnit}</DataField>
        <DataField label="Status">
          <Status location={invoice.location} />
        </DataField>
        {invoice.customerTrxId && (
            <Transmission customerTrxId={invoice.customerTrxId} channel="AWS" />
          )}
      </div>
    </ColumnLayout>
    <InvoiceLines lines={invoice.BaseLines || []} />
  </>
)

export default AWS
