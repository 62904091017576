import React, { useState } from 'react'
import {
  Table,
  TablePagination,
  Modal,
  Button,
} from '@amzn/awsui-components-react/polaris'

interface InvoiceLine {
  revenueAmount?: string
  description?: string
  lineNumber?: string
  lineType?: string
  unitSellingPrice?: string
  quantityInvoiced?: string
  taxLines?: TaxLine[]
}
interface TaxLine {
  taxLineAmount?: string
  taxLineNumber?: string
  taxName?: string
  taxRate?: string
  taxRateCode?: string
  taxRegimeCode?: string
}

const InvoiceLines = ({ lines }: { lines: InvoiceLine[] }) => {
  const [taxLines, setTaxLines] = useState<TaxLine[] | null>(null)

  return (
    <>
      <Table
        items={lines.sort(
          (line1, line2) => Number(line1.lineNumber) - Number(line2.lineNumber)
        )}
        features={['pagination']}
        variant="borderless"
        wrapLines={true}
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>This transaction has no lines.</b>
            </div>
          </div>
        }
        columnDefinitions={[
          {
            id: 'lineNumber',
            header: 'Line Number',
            cell: (item: InvoiceLine) => item.lineNumber,
          },
          {
            id: 'lineType',
            header: 'Line Type',
            cell: (item: InvoiceLine) => item.lineType,
          },
          {
            id: 'quantityInvoiced',
            header: 'Quantity',
            cell: (item: InvoiceLine) => item.quantityInvoiced,
          },
          {
            id: 'unitSellingPrice',
            header: 'Unit Price',
            cell: (item: InvoiceLine) => item.unitSellingPrice,
          },
          {
            id: 'revenueAmount',
            header: 'Amount',
            cell: (item: InvoiceLine) => item.revenueAmount,
          },
          {
            id: 'taxes',
            header: 'Taxes',
            cell: (item: InvoiceLine) =>
              item.taxLines && item.taxLines.length ? (
                <button
                  className="awsui-popover__trigger-text"
                  onClick={() => setTaxLines(item.taxLines!)}
                >
                  View
                </button>
              ) : (
                'None'
              ),
          },
          {
            id: 'description',
            header: 'Description',
            cell: (item: InvoiceLine) => item.description,
          },
        ]}
      >
        <TablePagination pageSize={25} />
      </Table>
      {taxLines && (
        <Modal
          header="Line Taxes"
          visible={true}
          footer={
            <span className="awsui-util-f-r">
              <Button onClick={() => setTaxLines(null)}>Close</Button>
            </span>
          }
          onDismiss={() => setTaxLines(null)}
        >
          <Table
            items={taxLines}
            variant="borderless"
            columnDefinitions={[
              {
                id: 'taxLineNumber',
                header: 'Tax Line Number',
                cell: (item) => item.taxLineNumber,
              },
              {
                id: 'taxName',
                header: 'Tax Name',
                cell: (item) => item.taxName,
              },
              {
                id: 'taxLineAmount',
                header: 'Tax Line Amount',
                cell: (item) => item.taxLineAmount,
              },
              {
                id: 'taxRate',
                header: 'Tax Rate',
                cell: (item) => item.taxRate,
              },
              {
                id: 'taxRateCode',
                header: 'Tax Rate Code',
                cell: (item) => item.taxRateCode,
              },
              {
                id: 'taxRegimeCode',
                header: 'Tax Regime Code',
                cell: (item) => item.taxRegimeCode,
              },
            ]}
          />
        </Modal>
      )}
    </>
  )
}

export default InvoiceLines
