import React from 'react'
import DataField from './DataField'
import {
  useZoomPdf,
  useZoomTransmissionDetails,
  useZoomCanReadRestrictedData,
} from '../api'
import { Popover, Icon } from '@amzn/awsui-components-react/polaris'
import TransmissionDetailsField from './TransmissionDetailsField'

const PermissionsGate = ({ customerTrxId,channel }: { customerTrxId: string,channel: string }) => {
  const { data: accessData, loading } = useZoomCanReadRestrictedData(channel)

  if (loading) return null

  if (!accessData?.canReadRestrictedData)
    return (
      <>
        <RequestPermissionsMessage label="Transmission Details" />
        <RequestPermissionsMessage label="PDF" />
      </>
    )

  return <Transmission customerTrxId={customerTrxId} channel={channel}/>
}

const RequestPermissionsMessage = ({ label }: { label: string }) => (
  <DataField
    label={
      <span>
        {label}{' '}
        <Popover
          size="small"
          position="top"
          triggerType="custom"
          dismissButton={false}
          content="Access to restricted data requires additional permissions."
        >
          <Icon name="lock-private" />
        </Popover>
      </span>
    }
  >
    <a
      href="https://tiny.amazon.com/3np9ri9c/ARBillingZoomAccessTemplate"
      target="_blank"
      rel="noopener noreferrer"
    >
      Request permissions
    </a>
  </DataField>
)

const Transmission = ({ customerTrxId,channel }: { customerTrxId: string,channel:string }) => {
  const { data: pdfData, loading: loadingPdf } = useZoomPdf(customerTrxId,channel)
  const {
    data: transmissionDetailsData,
    loading: loadingTransmissionDetails,
  } = useZoomTransmissionDetails(customerTrxId,channel)

  return (
    <>
      <DataField label="Transmission Details">
        <TransmissionDetailsField
          transmissionDetails={transmissionDetailsData?.transmissionDetails}
          loading={loadingTransmissionDetails}
        />
      </DataField>
      <DataField label="PDF">
        {loadingPdf ? (
          'Loading'
        ) : !pdfData?.pdf ? (
          <Popover
            content={'PDFs are not availabe for invoices prior to 10/17/2019.'}
          >
            Not Available
          </Popover>
        ) : (
          <a href={pdfData.pdf} target="_blank" rel="noopener noreferrer">
            Available
          </a>
        )}
      </DataField>
    </>
  )
}

export default PermissionsGate
