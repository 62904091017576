import React, { useState } from 'react'
import { Modal, Button } from '@amzn/awsui-components-react/polaris'
import { GetZoomTransmissionDetails } from '@amzn/zoom-dashboard-backend/src/types'

const TransmissionDetailsField = ({
  transmissionDetails: details,
  loading,
}: {
  transmissionDetails?: GetZoomTransmissionDetails.TransmissionDetails
  loading: boolean
}) => {
  const [show, setShow] = useState<any>(false)

  return (
    <>
      {loading ? (
        'Loading'
      ) : !details ? (
        'Not Available'
      ) : (
        <button
          className="awsui-popover__trigger-text"
          onClick={() => setShow(true)}
        >
          View
        </button>
      )}
      <Modal
        header="Transmission Details"
        visible={show}
        footer={
          <span className="awsui-util-f-r">
            <Button onClick={() => setShow(false)}>Close</Button>
          </span>
        }
        onDismiss={() => setShow(false)}
      >
        <pre>
          {[
            ['Customer Transaction ID', details?.customerTrxId || 'N/A'],
            ['Customer Name', details?.customerName || 'N/A'],
            ['Customer Number', details?.customerNumber || 'N/A'],
            ['OFA Invoice Number', details?.trxNumber || 'N/A'],
            ['Transaction Type', details?.trxTypeName || 'N/A'],
            ['Batch Source Name', details?.batchSourceName || 'N/A'],
            ['Original Generation Date', details?.originalGenDate || 'N/A'],
            ['Uploaded To VC', details?.uploadedToVc || 'N/A'],
          ].map(([label, value]) => `${label}: ${value}\n`)}
        </pre>
        {(details?.deliveryDetailsTable || []).map((deliveryDetails) => (
          <pre>
            {[
              ['From Email', deliveryDetails?.fromEmail || 'N/A'],
              ['To Email', deliveryDetails?.toEmail || 'N/A'],
              ['CC Email', deliveryDetails?.ccEmail || 'N/A'],
              ['BCC Email', deliveryDetails?.bccEmail || 'N/A'],
              ['Status', deliveryDetails?.status || 'N/A'],
              ['Status Message', deliveryDetails?.statusMessage || 'N/A'],
              ['Email Subject', deliveryDetails?.emailSubject || 'N/A'],
              ['Email Sent Date', deliveryDetails?.emailSentDate || 'N/A'],
            ].map(([label, value]) => `${label}: ${value}\n`)}
          </pre>
        ))}
      </Modal>
    </>
  )
}

export default TransmissionDetailsField
