import React from 'react'
import './DateRangePicker.scss'

// takes take two DatePicker components as props and displays them together to look like a compound input
const DateRangePicker = ({ from, to }: { from: any; to: any }) => (
  <div className="date-range-group">
    {from}
    <div className="date-range-group-spacer" />
    {to}
  </div>
)

export default DateRangePicker
