import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import reduxStore from './store'
import { Auth } from '@aws-amplify/auth'
import Layout from './Layout'

Auth.configure({
  region: 'us-west-2',
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_URL,
    scope: ['openid'],
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    responseType: 'code',
  },
  storage: sessionStorage,
})

const REDIRECT_URL_LOCAL_STORAGE_KEY = 'redirectUrlAfterAuthentication'

const App = () => {
  const [signedIn, setSignedIn] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setSignedIn(true)
        setError(null)
        const redirectUrl = sessionStorage.getItem(
          REDIRECT_URL_LOCAL_STORAGE_KEY
        )
        if (redirectUrl) {
          sessionStorage.removeItem(REDIRECT_URL_LOCAL_STORAGE_KEY)
          window.location.assign(redirectUrl)
        }
      })
      .catch(() => {
        setSignedIn(false)
        setError(null)
        // if a user needs to be authenticated, store the URL they were attempting to
        // access so we can redirect them to it after authentication
        sessionStorage.setItem(
          REDIRECT_URL_LOCAL_STORAGE_KEY,
          window.location.href
        )

        Auth.federatedSignIn({
          customProvider: process.env.REACT_APP_COGNITO_PROVIDER_NAME as string,
        })
      })
  }, [])

  if (error) return <div>Error: {error}</div>
  if (!signedIn) return <div>Signing in...</div>

  return (
    <Provider store={reduxStore}>
      <Router>
        <Layout />
      </Router>
    </Provider>
  )
}

export default App
