import React, { useState } from 'react'
import { Select, Icon, Button, Alert } from '@amzn/awsui-components-react/polaris'
import AWS from './AWS'
import ContraCogs from './ContraCogs'
import VendorReturns from './VendorReturns'
import { BusinessChannel } from '../types'
import { ACCESS_NOTICE, ACCESS_NOTICE_URL, TEAM_URL } from '../constants'
import GetAccess from './GetAccess'
import Advertising from './Advertising'

const CommonSearch = (props: { authorizedBusinessChannels: BusinessChannel, onboardedBusinessChannels: BusinessChannel}) => {

  const [getAccessFlag, setGetAccessFlag] = useState(
    false
  )

  const [businessChannels, setBusinessChannels] = useState(
    props.authorizedBusinessChannels
  )

  const [ofaBusinessChannel, setOfaBusinessChannel] = useState(
    businessChannels[0]?.id
  )

  const handleAccess = () => {
    let flag, channelList;
    if(getAccessFlag) {
      flag=false
      channelList=props.authorizedBusinessChannels
    } else {
      flag=true
      channelList=props.onboardedBusinessChannels
    }
    setGetAccessFlag(flag)
    setBusinessChannels(channelList)
    setOfaBusinessChannel(channelList[0].id)
  }

  return (
    <>
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>{getAccessFlag? "Select Business Channel for which access is required": "Business Channel"}</h2>
            </div>
            <div className="awsui-util-action-stripe-group">
              <Select
                selectedOption={businessChannels.find(
                  (s) => s.id === ofaBusinessChannel
                )}
                options={businessChannels}
                onChange={(e) => setOfaBusinessChannel(e.detail.selectedId)}
              />
              <Button
                variant={'link'}
                onClick={handleAccess}>
                <div style={{color:"#0073bb"}}>{getAccessFlag?"Back to Main Page":"Get Access"}</div>
              </Button>
              <a
                className="awsui-util-font-size-2 awsui-util-pt-s"
                href={TEAM_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {"Learn more "}
                <span>
                  <Icon variant="link" name="external"></Icon>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div><LoadChildChannel selectedBusinessChannel={ofaBusinessChannel} getAccessFlag={getAccessFlag}/></div>
      </div>
      {getAccessFlag ? <MessageBanner/> : <></>}
    </>
  )
}

const LoadChildChannel = (props: {selectedBusinessChannel:string, getAccessFlag: boolean}) => {
  if(props.getAccessFlag) {
    return <GetAccess businessChannel={props.selectedBusinessChannel} />
  } else if (props.selectedBusinessChannel === 'AWS') {
    return <AWS />
  } else if (props.selectedBusinessChannel === 'Distributor Returns') {
    return <VendorReturns />
  } else if (props.selectedBusinessChannel === 'Contra CoGS') {
    return <ContraCogs />
  } else if (props.selectedBusinessChannel === 'Advertising') {
    return <Advertising />
  } else {
    return (
      <div>
        <p>You Dont have access to any business Channel</p>
      </div>
    )
  }
}

const MessageBanner = () => {
  return <Alert header="Notice">
    <p>{ACCESS_NOTICE}
      <a
        className="awsui-util-font-size-2 awsui-util-pt-s"
        href={ACCESS_NOTICE_URL}
        target="_blank"
        rel="noopener noreferrer">
        <span>
          <Icon variant="link" name="external"></Icon>
        </span>
      </a>
    </p>
  </Alert>
}

export default CommonSearch