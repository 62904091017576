import React, { useEffect, useState } from 'react'
import { ColumnLayout, Icon, RadioGroup } from '@amzn/awsui-components-react'
import { useZoomOnboardedChannels } from '../api'

const GetAccess = (props: { businessChannel: string }) => {

  const accessOptions = [
    {value: "Unrestricted", label: "Access to View Transaction Data Only"},
    {value: "Restricted", label: "Access to View Transaction Data and Download Transaction PDF copies"}
  ]

  const { data, loading} = useZoomOnboardedChannels()

  const [selectedAccessOptions, setSelectedAccessOptions] = useState(accessOptions[0].value)

  const [accessLink, setAccessLink] = useState<string>(data?.channelConfiguration[props.businessChannel].unrestrictedDataAccessTeam)

  useEffect(()=>{
    if(selectedAccessOptions===accessOptions[0].value)
      setAccessLink(data?.channelConfiguration[props.businessChannel].unrestrictedDataAccessTeam)
    else
      setAccessLink(data?.channelConfiguration[props.businessChannel].restrictedDataAccessTeam)
  },[selectedAccessOptions, accessOptions, data, props.businessChannel])

  if(loading) return null

  return (
    <ColumnLayout columns={1}>
      <div
        data-awsui-column-layout-root="true">
        <RadioGroup
          value={selectedAccessOptions}
          items={accessOptions}
          onChange={(e)=>{
            setSelectedAccessOptions(e.detail.value)
        }}/>
        <div>Share the following team link with your manager and get yourself added to it:</div>
        {
          <a className='awsui-util-font-size-2 awsui-util-pt-s'
             href={accessLink}
             target='_blank'
             rel='noopener noreferrer'>
            {accessLink.substring(accessLink.lastIndexOf("/")+1) + " "}
            <span>
              <Icon variant='link' name='external'></Icon>
            </span>
          </a>
        }
      </div>
    </ColumnLayout>
  )
}

export default GetAccess