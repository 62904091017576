import React, { useState } from 'react'
import {
  Form,
  FormField,
  Input,
  ColumnLayout,
  Button,
  Table,
  TablePagination,
  TableSorting,
  DatePicker,
} from '@amzn/awsui-components-react/polaris'
import DataField from './DataField'
import InvoiceLines from './InvoiceLines'
import Status from './Status'
import { useZoomAdvertising } from '../api'
import { PostZoomAdvertising } from '@amzn/zoom-dashboard-backend/src/types'
import DateRangePicker from './DateRangePicker'
import dateFormatter from './dateFormatter'
import InvoiceErrors from './InvoiceErrors'
import Transmission from './Transmission'
import {
  AMOUNT_FIELD, AMOUNT_REMAINING_FIELD,
  CREATION_DATE_FIELD,
  CURRENCY_FIELD, DATE_RANGE_FIELD, GL_DATE_FIELD, NAME_FIELD,
  ORG_NAME_FIELD,
  STAGE_FIELD, STATUS_FIELD, TOTAL_AMOUNT_FIELD, TRX_DATE_FIELD,
  TRX_NUMBER_FIELD
} from '../constants'

const Advertising = () => {
  const [transactionNumber, setTransactionNumber] = useState('')
  const [operatingUnit, setOperatingUnit] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [query, setQuery] = useState<PostZoomAdvertising.Request>({})
  const [error, setError] = useState<string | null>(null)
  const { data, loading } = useZoomAdvertising(query)
  const [invoice, setInvoice] = useState<PostZoomAdvertising.Invoice | null>(null)

  if (invoice)
    return <DisplayInvoice invoice={invoice} onClose = {() => setInvoice(null)} />

  const onSubmit = (e: any) => {
    e.preventDefault()
    if ((fromDate || toDate) && !operatingUnit) {
      setError(
        'If you set the date filters, you must also specify an Organization Name.'
      )
    } else if (operatingUnit && !(fromDate || toDate)) {
      setError(
        'If you specify an Organization Name, you must also set the date filters.'
      )
    } else {
      setQuery({
        transactionNumber,
        operatingUnit,
        fromDate: dateFormatter(fromDate),
        toDate: dateFormatter(toDate),
      })
      setError(null)
    }
  }

  return (
    <>
      <form onSubmit = {onSubmit}>
        <Form
          actions = {
            <div className = "awsui-util-pt-m">
              <Button variant = "primary" onClick = {onSubmit}>
                Search
              </Button>
              <Button
                onClick = {() => {
                  setOperatingUnit('')
                  setTransactionNumber('')
                  setFromDate('')
                  setToDate('')
                }}
              >
                Clear
              </Button>
            </div>
          }
          errorText = {error}
        >
          <ColumnLayout reset-filter-link columns = {3}>
            <div
              data-awsui-column-layout-root = "true"
              style = {{ paddingBottom: 5 }}
            >
              <FormField label = {TRX_NUMBER_FIELD}>
                <Input
                  controlId = "advtrxnum"
                  placeholder = "e.g. 1625663893"
                  value = {transactionNumber}
                  onChange = {(e) => setTransactionNumber(e.detail.value)}
                />
              </FormField>
            </div>
          </ColumnLayout>
          <ColumnLayout reset-filter-link columns = {3}>
            <div
              data-awsui-column-layout-root = "true"
              style = {{ paddingBottom: 5 }}
            >
              <FormField label = {ORG_NAME_FIELD}>
                <Input
                  controlId = "orgname"
                  placeholder = "e.g. AMAZON.AU"
                  value = {operatingUnit}
                  onChange = {(e) => setOperatingUnit(e.detail.value)}
                />
              </FormField>
              <FormField label = {DATE_RANGE_FIELD}>
                <DateRangePicker
                  from = {
                    <DatePicker
                      controlId = "advfrom"
                      value = {fromDate}
                      onChange = {(e) => setFromDate(e.detail.value)}
                    />
                  }
                  to = {
                    <DatePicker
                      controlId = "advto"
                      value = {toDate}
                      onChange = {(e) => setToDate(e.detail.value)}
                    />
                  }
                />
              </FormField>
            </div>
          </ColumnLayout>
        </Form>
      </form>
      <Table
        loading = {loading}
        loadingText = "Searching"
        columnDefinitions = {columnDefinitions}
        items = {data?.invoices || []}
        features = {['pagination', 'sorting']}
        variant = "borderless"
        onRowClick = {(e) => setInvoice(e.detail.item)}
        className = "search-results"
        empty = {
          <div className = "awsui-util-t-c">
            <div className = "awsui-util-pt-s awsui-util-mb-xs">
              <b>No matches</b>
            </div>
          </div>
        }
      >
        <TablePagination pageSize = {25} />
        <TableSorting
          sortableColumns = {columnDefinitions.map((c) => ({
            id: c.id as string,
            field: c.id,
          }))}
        />
      </Table>
    </>
  )
}

const columnDefinitions: Table.ColumnDefinition[] = [
  {
    cell: (item: PostZoomAdvertising.Invoice) => item.trxNumber,
    header: TRX_NUMBER_FIELD,
    id: 'trxNumber',
  },
  {
    cell: (item: PostZoomAdvertising.Invoice) =>
      item.invoiceCurrencyCode || item.currencyCode, // invoices in interface tables have slightly different fields
    header: CURRENCY_FIELD,
    id: 'invoiceCurrencyCode',
  },
  {
    cell: (item: PostZoomAdvertising.Invoice) => item.location,
    header: STAGE_FIELD,
    id: 'location',
  },
  {
    cell: (item: PostZoomAdvertising.Invoice) => item.creationDate,
    header: CREATION_DATE_FIELD,
    id: 'creationDate',
  },
  {
    cell: (item: PostZoomAdvertising.Invoice) =>
      item.amountDueOriginal || item.totalAmount,
    header: AMOUNT_FIELD,
    id: 'amountDueOriginal',
  },
  {
    cell: (item: PostZoomAdvertising.Invoice) => item.operatingUnit,
    header: ORG_NAME_FIELD,
    id: 'operatingUnit',
  },
]

const DisplayInvoice = ({
                          invoice,
                          onClose,
                        }: {
  invoice: PostZoomAdvertising.Invoice
  onClose: () => void
}) => (
  <>
    <Button onClick = {onClose}> Back to Search Results </Button>
    <div style = {{ height: '2em' }} />
    <InvoiceErrors errors = {invoice.errorStatus?.errorList} />
    <ColumnLayout reset-filter-link columns = {4}>
      <div data-awsui-column-layout-root = "true">
        <DataField label = {TRX_NUMBER_FIELD}> {invoice.trxNumber} </DataField>
        <DataField label = {CURRENCY_FIELD}>
          {invoice.invoiceCurrencyCode || invoice.currencyCode}
        </DataField>
        <DataField label = {NAME_FIELD}> {invoice.name} </DataField>
        <DataField label = {STAGE_FIELD}> {invoice.location} </DataField>
        <DataField label = {TOTAL_AMOUNT_FIELD}>
          {invoice.amountDueOriginal || invoice.totalAmount}
        </DataField>
        <DataField label = {AMOUNT_REMAINING_FIELD}>
          {invoice.amountDueOriginal}
        </DataField>
        <DataField label = {TRX_DATE_FIELD}> {invoice.trxDate} </DataField>
        <DataField label = {GL_DATE_FIELD}> {invoice.glDate} </DataField>
        <DataField label = {CREATION_DATE_FIELD}> {invoice.creationDate} </DataField>
        <DataField label = {ORG_NAME_FIELD}> {invoice.operatingUnit} </DataField>
        <DataField label = {STATUS_FIELD}>
          <Status location = {invoice.location} />
        </DataField>
        {invoice.customerTrxId && (
          <Transmission customerTrxId = {invoice.customerTrxId} channel = "Advertising" />
        )}
      </div>
    </ColumnLayout>
    <InvoiceLines lines = {invoice.BaseLines || []} />
  </>
)

export default Advertising
