import React from 'react'
import { Link } from 'react-router-dom'
import Errors from './Errors'
import Zoom from '../Zoom'

import logo from './logo.png'
import './index.scss'

const isDevStage = window.location.href.toLowerCase().includes('dev.zoom')

const Layout = () => (
  <div
    className={`awsui awsui-motion whole-page ${
      isDevStage ? 'awsui-polaris-dark-mode' : ''
    }`}
  >
    <Errors />
    <nav className={`website-navigation-top`}>
      <div className="website-navigation-top-logo">
        <Link to="/" className="logo-link">
          <img src={logo} alt="logo" className="logo" />
          Zoom
        </Link>
      </div>
    </nav>
    <div className="website-content">
      <Zoom />
    </div>
  </div>
)

export default Layout
