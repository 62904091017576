import {
  SET_TOKEN,
  DISMISS_ERROR,
  PUSH_ERROR,
  REHYRDATE_STATE,
} from './actions'

export interface State {
  errors: string[]
  token: string | null
}
export interface Action {
  type: string
  [key: string]: any
}
export const initialState: State = {
  errors: [],
  token: null,
}

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case PUSH_ERROR:
      return { ...state, errors: [...state.errors, action.message] }
    case DISMISS_ERROR:
      return {
        ...state,
        errors: [
          ...state.errors.slice(0, action.index),
          ...state.errors.slice(action.index + 1),
        ],
      }
    case SET_TOKEN:
      return { ...state, token: action.token }
    case REHYRDATE_STATE:
      return { ...state, ...action.state }
    default:
      return state
  }
}
export default reducer
