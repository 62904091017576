import React from 'react'
import { Alert } from '@amzn/awsui-components-react/polaris'

const InvoiceErrors = ({ errors }: { errors?: string[] }) => {
  if (!errors || !errors.length) return null
  return (
    <>
      <Alert header="Invoice Errors" type="error">
        <ul>
          {errors.map((error, i) => (
            <li key={i}>{error}</li>
          ))}
        </ul>
      </Alert>
      <div style={{ height: '2em' }} />
    </>
  )
}

export default InvoiceErrors
