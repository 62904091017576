export const PUSH_ERROR = 'PUSH_ERROR'
export const DISMISS_ERROR = 'DISMISS_ERROR'
export const SET_TOKEN = 'SET_TOKEN'
export const REHYRDATE_STATE = 'REHYRDATE_STATE'

export const pushError = (message: string) => ({
  type: PUSH_ERROR,
  message,
})

export const dismissError = (index: number) => ({
  type: DISMISS_ERROR,
  index,
})

export const setToken = (token: string) => ({
  type: SET_TOKEN,
  token,
})

export const rehyrdateState = (state: any) => ({
  type: REHYRDATE_STATE,
  state,
})
