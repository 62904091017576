import React from 'react'
import Flash from '@amzn/awsui-components-react/polaris/flash'
import { dismissError } from '../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../store/reducer'

const Errors = () => {
  const errors = useSelector((state: State) => state.errors).slice(0, 10)
  const dispatch = useDispatch()
  return (
    <div className="error-box">
      {errors.map((error, i) => (
        <Flash
          dismissible={true}
          key={i}
          type="error"
          onDismiss={() => dispatch(dismissError(i))}
        >
          {error}
        </Flash>
      ))}
    </div>
  )
}

export default Errors
