import React from 'react'

import { BusinessChannel } from '../types'
import CommonSearch from './CommonSearch'
import { useZoomOnboardedChannels, useZoomAllowedChannels } from '../api'
import './index.scss'

const Zoom = () => {
  let businessChannel: BusinessChannel = []
  let onboardedChannel: BusinessChannel = []

  const { data: allChannelData, loading: loader2} = useZoomOnboardedChannels()
  const { data: allowedChanelData, loading:  loader1} = useZoomAllowedChannels()

  if (loader1 && loader2) return null
  const allowedBusinessChannel = allowedChanelData?.allowedChannels
  const onboardedBusinessChannel = Object.keys(allChannelData?.channelConfiguration)
  if (allowedBusinessChannel === undefined || onboardedBusinessChannel ===undefined) {
    return <div>loading...</div>
  } else {
    allowedBusinessChannel.sort()
    allowedBusinessChannel.reverse()
    allowedBusinessChannel.forEach((item:string) => {
      businessChannel.push({ id: item, label: item })
    })
    onboardedBusinessChannel.sort()
    onboardedBusinessChannel.reverse()
    onboardedBusinessChannel.forEach((item:string) => {
      if(item!=="stage")
        onboardedChannel.push({ id: item, label: item })
    })
  }

  return (
    <div className="awsui-grid awsui-util-p-m util-full-width zoom">
      <CommonSearch authorizedBusinessChannels={businessChannel} onboardedBusinessChannels={onboardedChannel} />
    </div>
  )
}

export default Zoom
