import { useEffect, useState } from 'react'
import { pushError } from './store/actions'
import { Auth } from '@aws-amplify/auth'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { useDispatch } from 'react-redux'
import {
  PostZoomAWS,
  PostZoomContraCogs,
  PostZoomVendorReturns,
  GetZoomPdf,
  GetZoomTransmissionDetails,
  GetZoomCanReadRestrictedData,
  GetZoomAllowedChannels, PostZoomAdvertising
} from '@amzn/zoom-dashboard-backend/src/types'
import { GetOnboardedChannels } from '@amzn/zoom-dashboard-backend/src/routes/zoom/getOnboardedChannels'

export interface ErrorObject {
  unauthorized?: boolean
}

export const useFetch = <T>(
  url: string,
  options?: {
    skip?: boolean
    [key: string]: any
  }
): { loading: boolean; error?: ErrorObject; data?: T } => {
  const [data, setData] = useState(undefined)
  const [error, setError] = useState<ErrorObject | undefined>(undefined)
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()

  const optionsString = JSON.stringify(options || {})

  useEffect(() => {
    const options = JSON.parse(optionsString)

    // if skip is true, don't attempt to get any data
    if (options?.skip) {
      setLoading(false)
      return
    }

    const fetchData = async () => {
      try {
        setData(undefined)
        setLoading(true)
        setError(undefined)

        const session = await getSession()

        const res = await fetch(url, {
          ...(options || {}),
          headers: {
            authentication: session.getIdToken().getJwtToken() as string,
            'Content-Type': 'application/json',
          },
        })
        console.log({ res })
        if (!res.ok) {
          console.log({ res })
          if (res.status === 401) setError({ unauthorized: true })
          else setError({})
        } else {
          console.log({ res })
          const json = await res.json()
          setData(json)
        }
        setLoading(false)
      } catch (error) {
        setError({}) // makes error truthy even if it does't specify a cause
        setLoading(false)
        dispatch(
          pushError(
            `The API had trouble processing some parts of this request.`
          )
        )
        console.error({ error })
      }
    }
    fetchData()
  }, [url, dispatch, optionsString])
  return { data, error, loading }
}

const getSession = async (): Promise<CognitoUserSession> => {
  const session = await Auth.currentSession()

  // amplify doesn't reliably refresh tokens so manually check and refresh it.
  // if the token expires within 1 minute from now, refresh it
  if (session.getIdToken().getExpiration() - Date.now() / 1000 < 60) {
    const user = await Auth.currentUserPoolUser()
    return new Promise((resolve, reject) =>
      user.refreshSession(session.getRefreshToken(), (error: any, data: any) =>
        error ? reject(error) : resolve(data)
      )
    )
  } else {
    return session
  }
}

const isEmptyObject = (obj: any): boolean => Object.keys(obj).length === 0

export const useZoomAWS = (params: PostZoomAWS.Request) =>
  useFetch<PostZoomAWS.Response>(`/api/zoom/aws`, {
    method: 'POST',
    body: JSON.stringify(params),
    skip: isEmptyObject(params),
  })

export const useZoomContraCogs = (params: PostZoomContraCogs.Request) =>
  useFetch<PostZoomContraCogs.Response>(`/api/zoom/contraCogs`, {
    method: 'POST',
    body: JSON.stringify(params),
    skip: isEmptyObject(params),
  })

export const useZoomVendorReturns = (params: PostZoomVendorReturns.Request) =>
  useFetch<PostZoomVendorReturns.Response>(`/api/zoom/vendorReturns`, {
    method: 'POST',
    body: JSON.stringify(params),
    skip: isEmptyObject(params),
  })

export const useZoomAdvertising = (params: PostZoomAdvertising.Request) =>
  useFetch<PostZoomAdvertising.Response>(`/api/zoom/advertising`, {
    method: 'POST',
    body: JSON.stringify(params),
    skip: isEmptyObject(params),
  })

export const useZoomPdf = (customerTrxId: string,channel:string) =>
  useFetch<GetZoomPdf.Response>(`/api/zoom/${customerTrxId}/${channel}/pdf`, {
    skip: !customerTrxId,
  })

export const useZoomTransmissionDetails = (customerTrxId: string,channel:string) =>
  useFetch<GetZoomTransmissionDetails.Response>(
    `/api/zoom/${customerTrxId}/${channel}/transmissionDetails`,
    { skip: !customerTrxId }
  )

export const useZoomCanReadRestrictedData = (channel: string) =>
  useFetch<GetZoomCanReadRestrictedData.Response>(
    `/api/zoom/${channel}/canReadRestrictedData`
  )

export const useZoomAllowedChannels = () =>
  useFetch<GetZoomAllowedChannels.Response>(
    `/api/zoom/allowedChannels`
  )

export const useZoomOnboardedChannels = () =>
  useFetch<GetOnboardedChannels.Response>(
    `/api/zoom/onboardedChannels`
  )

