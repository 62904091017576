import React, { useState } from 'react'
import {
  Form,
  FormField,
  Input,
  ColumnLayout,
  Button,
  Table,
  TablePagination,
  DatePicker,
  TableSorting,
} from '@amzn/awsui-components-react/polaris'
import DataField from './DataField'
import InvoiceLines from './InvoiceLines'
import Status from './Status'
import { useZoomVendorReturns } from '../api'
import { PostZoomVendorReturns } from '@amzn/zoom-dashboard-backend/src/types'
import Transmission from './Transmission'
import DateRangePicker from './DateRangePicker'
import dateFormatter from './dateFormatter'
import InvoiceErrors from './InvoiceErrors'

const VendorReturns = () => {
  const [returnId, setReturnId] = useState('')
  const [shipmentId, setShipmentId] = useState('')
  const [ofaTransactionNumber, setOfaTransactionNumber] = useState('')
  const [vendorCode, setVendorCode] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [query, setQuery] = useState<PostZoomVendorReturns.Request>({})
  const [error, setError] = useState<string | null>(null)
  const { data, loading } = useZoomVendorReturns(query)
  const [invoice, setInvoice] = useState<PostZoomVendorReturns.Invoice | null>(
    null
  )
  if (invoice)
    return <DisplayInvoice invoice={invoice} onClose={() => setInvoice(null)} />

  const onSubmit = (e: any) => {
    e.preventDefault()
    if ((fromDate || toDate) && !vendorCode) {
      setError(
        'If you set the date filters, you must also specify the vendor code.'
      )
    } else if (vendorCode && !(fromDate || toDate)) {
      setError(
        'If you specify the vendor code, you must also set the date filters.'
      )
    } else {
      setQuery({
        returnId,
        shipmentId,
        ofaTransactionNumber,
        vendorCode,
        fromDate: dateFormatter(fromDate),
        toDate: dateFormatter(toDate),
      })
      setError(null)
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Form
          actions={
            <div className="awsui-util-pt-m">
              <Button variant="primary" onClick={onSubmit}>
                Search
              </Button>
              <Button
                onClick={() => {
                  setReturnId('')
                  setShipmentId('')
                  setOfaTransactionNumber('')
                  setVendorCode('')
                  setFromDate('')
                  setToDate('')
                }}
              >
                Clear
              </Button>
            </div>
          }
          errorText={error}
        >
          <ColumnLayout reset-filter-link columns={3}>
            <div
              data-awsui-column-layout-root="true"
              style={{ paddingBottom: 5 }}
            >
              <FormField label="Return ID">
                <Input
                  controlId="vtreturnid"
                  placeholder="e.g. 5082184433552"
                  value={returnId}
                  onChange={(e) => setReturnId(e.detail.value)}
                />
              </FormField>
              <FormField label="Shipment ID">
                <Input
                  controlId="vtshipmentid"
                  placeholder="e.g. VRET4998499838532"
                  value={shipmentId}
                  onChange={(e) => setShipmentId(e.detail.value)}
                />
              </FormField>
              <FormField label="OFA Transaction Number">
                <Input
                  controlId="vtofatrxnum"
                  placeholder="e.g. 5800444054"
                  value={ofaTransactionNumber}
                  onChange={(e) => setOfaTransactionNumber(e.detail.value)}
                />
              </FormField>
            </div>
          </ColumnLayout>
          <ColumnLayout reset-filter-link columns={3}>
            <div
              data-awsui-column-layout-root="true"
              style={{ paddingBottom: 5 }}
            >
              <FormField label="Vendor Code">
                <Input
                  controlId="vtvendorcode"
                  placeholder="e.g. MMTGM"
                  value={vendorCode}
                  onChange={(e) => setVendorCode(e.detail.value)}
                />
              </FormField>
              <FormField label="Date Range">
                <DateRangePicker
                  from={
                    <DatePicker
                      controlId="vtfrom"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.detail.value)}
                    />
                  }
                  to={
                    <DatePicker
                      controlId="vtto"
                      value={toDate}
                      onChange={(e) => setToDate(e.detail.value)}
                    />
                  }
                />
              </FormField>
            </div>
          </ColumnLayout>
        </Form>
      </form>
      <Table
        loading={loading}
        loadingText="Searching"
        columnDefinitions={columnDefinitions}
        items={data?.invoices || []}
        features={['pagination', 'sorting']}
        variant="borderless"
        wrapLines={false}
        onRowClick={(e) => setInvoice(e.detail.item)}
        className="search-results"
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No matches</b>
            </div>
          </div>
        }
      >
        <TablePagination pageSize={25} />
        <TableSorting
          sortableColumns={columnDefinitions.map((c) => ({
            id: c.id as string,
            field: c.id,
          }))}
        />
      </Table>
    </>
  )
}

const columnDefinitions: Table.ColumnDefinition[] = [
  {
    cell: (item: PostZoomVendorReturns.Invoice) => item.returnId,
    header: 'Return ID',
    id: 'returnId',
  },
  {
    cell: (item: PostZoomVendorReturns.Invoice) => item.shipmentRequestId,
    header: 'Shipment ID',
    id: 'shipmentRequestId',
  },
  {
    cell: (item: PostZoomVendorReturns.Invoice) => item.trxNumber,
    header: 'OFA Transaction Number',
    id: 'trxNumber',
  },
  {
    // transactions from staging or interface tables store their totals in a different field so display whichever one is available
    cell: (item: PostZoomVendorReturns.Invoice) =>
      item.amountDueOriginal || item.totalAmount,
    header: 'Amount',
    id: 'amountDueOriginal',
  },
  {
    cell: (item: PostZoomVendorReturns.Invoice) =>
      item.invoiceCurrencyCode || item.currencyCode,
    header: 'Currency',
    id: 'invoiceCurrencyCode',
  },
  {
    cell: (item: PostZoomVendorReturns.Invoice) => item.location,
    header: 'Stage',
    id: 'location',
  },
  {
    cell: (item: PostZoomVendorReturns.Invoice) => item.creationDate,
    header: 'Creation Date',
    id: 'creationDate',
  },
  {
    cell: (item: PostZoomVendorReturns.Invoice) => item.operatingUnit,
    header: 'Org Name',
    id: 'operatingUnit',
  },
  {
    cell: (item: PostZoomVendorReturns.Invoice) =>
      item.originalSystemReference || item.vendorCode,
    header: 'Vendor Code',
    id: 'originalSystemReference',
  },
]

const DisplayInvoice = ({
  invoice,
  onClose,
}: {
  invoice: PostZoomVendorReturns.Invoice
  onClose: () => void
}) => (
  <>
    <Button onClick={onClose}>Back to Search Results</Button>
    <div style={{ height: '2em' }} />
    <InvoiceErrors errors={invoice.errorStatus?.errorList} />
    <ColumnLayout reset-filter-link columns={4}>
      <div data-awsui-column-layout-root="true">
        <DataField label="Return ID">{invoice.returnId}</DataField>
        <DataField label="Shipment ID">{invoice.shipmentRequestId}</DataField>
        <DataField label="Total Amount">
          {invoice.amountDueOriginal || invoice.totalAmount}
        </DataField>
        <DataField label="Amount Remaining">
          {invoice.amountDueRemaining}
        </DataField>
        <DataField label="Currency">
          {invoice.invoiceCurrencyCode || invoice.currencyCode}
        </DataField>
        <DataField label="Vendor Code">
          {invoice.originalSystemReference || invoice.vendorCode}
        </DataField>
        <DataField label="Transaction Date">{invoice.trxDate}</DataField>
        <DataField label="GL Date">{invoice.glDate}</DataField>
        <DataField label="Party Name">{invoice.partyName}</DataField>
        <DataField label="Creation Date">{invoice.creationDate}</DataField>
        <DataField label="Org Name">{invoice.operatingUnit}</DataField>
        <DataField label="Status">
          <Status location={invoice.location} />
        </DataField>
        {invoice.customerTrxId && (
          <Transmission customerTrxId={invoice.customerTrxId} channel="Distributor Returns"/>
        )}
      </div>
    </ColumnLayout>
    <InvoiceLines lines={invoice.BaseLines || []} />
  </>
)

export default VendorReturns
