import React from 'react'
import { Icon } from '@amzn/awsui-components-react/polaris'

const Status = ({ location }: { location?: string }) => {
  if (location === 'STAGING')
    return (
      <span className="awsui-util-status-inactive">
        <Icon name="status-pending" /> Staged
      </span>
    )
  else if (location === 'INTERFACE')
    return (
      <span className="awsui-util-status-inactive">
        <Icon name="status-pending" /> Interfaced
      </span>
    )
  else if (location === 'BASE')
    return (
      <span className="awsui-util-status-positive">
        <Icon name="status-positive" /> Billed
      </span>
    )
  else return null
}

export default Status
